import React , {useState} from 'react'

import "./FormularioModal.css"

const FormularioModal = ( props ) => {

    const [lugar, setLugar] = useState(false);

    const handleInputChange = event => {
        console.log(event)
        const { name, value } = event.target
        props.setInvitado({ ...props.invitado, [name]: value })
        console.log(props.invitado);
    }

    const handleCheckBoxChange = event => {
        //console.log(event.target.checked)
        const { name, checked } = event.target
        props.setInvitado({ ...props.invitado, [name]: (checked? '1' : '0') })
        //console.log(invitado);
        if(checked){
            setLugar(true);
        }else{
            setLugar(false);
        }
    }      

    return ( 
        <div className="form-group">

            {/* <header>
                <h1>
                    <a href="#">
                        <img src="http://tfgms.com/sandbox/dailyui/logo-1.png" alt="Authentic Collection"/>
                    </a>
                </h1>
            </header> */}
            <h2 class="text-center">Gabi &amp; Ariel</h2>
            <label htmlFor="campo1">Nombre de los adultos</label>
            <input className="form-control" type="text"
                name="campo1" id="campo1"
                onChange={handleInputChange} value={props.invitado.campo1}
            />
            <label htmlFor="campo2" style={{ marginTop: `7px` }}>Cantidad de adultos</label>
            <span>: &nbsp; </span>
            <select className="custom-select mr-sm-2 combos" id="campo2" name="campo2" 
                    onChange={handleInputChange} 
                    value={props.invitado.campo2}>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
            </select>
            <hr size="5" width="100%" ></hr>  
            <label htmlFor="campo3">Nombre de los ni&ntilde;os</label>
            <input className="form-control"  type="text"
                name="campo3" id="campo3"
                onChange={handleInputChange} value={props.invitado.campo3}
            />
            <label htmlFor="campo4" style={{ marginTop: `7px` }}>Cantidad de ni&ntilde;os</label>
            <span>: &nbsp; </span>
            <select className="custom-select mr-sm-2 combos" id="campo4" name="campo4" 
                    onChange={handleInputChange} 
                    value={props.invitado.campo4}>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
            </select>
            <br/>
            <hr size="5" width="100%" ></hr>  
            <label htmlFor="campo5">Alergias / Intolerancias</label>
            <span>: &nbsp; </span>
            <input className="form-control"  type="text"
                name="campo5" id="campo5"
                onChange={handleInputChange} value={props.invitado.campo5}
            />
            <label htmlFor="campo6" style={{ marginTop: `7px` }}>¿Contamos contigo para el autobús?&nbsp;
                <input class="form-check-input" type="checkbox" 
                            name="campo6" id="campo6"  
                            onChange={handleCheckBoxChange}  
                            value={props.invitado.campo6} 
                            />
            </label>
            { lugar ? 
                <div>
                    <label htmlFor="campo7" style={{ marginTop: `7px` }}>Si has marcado la opción anterior, escribe si lo cogerás en Valencia o La Eliana</label>
                    <span> &nbsp; </span>
                    <select className="custom-select mr-sm-2 combos" id="campo7" name="campo7" 
                            onChange={handleInputChange} 
                            value={props.invitado.campo7}>
                        <option value={0}>Valencia</option>
                        <option value={1}>La Eliana</option>
                    </select> 
                </div>
                : ""
            }

            <label htmlFor="campo8" style={{ marginTop: `7px` }}>Si eres chica, dinos tu número de zapato</label>
            <input className="form-control" type="text"
                name="campo8" id="campo8"
                onChange={handleInputChange} value={props.invitado.campo8}
            /> 

            <label htmlFor="campo9" style={{ marginTop: `7px` }}>Elige tu canción favorita para la fiesta</label>
            <input className="form-control" type="text"
                name="campo9" id="campo9"
                onChange={handleInputChange} value={props.invitado.campo9}
            /> 

            {/* <label htmlFor="comeCerdo" style={{ marginTop: `7px` }}>¿Deseas utilizar el servicio de autobus?</label>
            <span> &nbsp; </span>
            <select className="custom-select mr-sm-2" id="comeCerdo" name="comeCerdo" 
                    onChange={handleInputChange} 
                    value={props.invitado.comeCerdo}>
                <option value={0}>No</option>
                <option value={1}>Si</option>
            </select> */}
            {/* <br/> */}
            {/* <label htmlFor="telefono" style={{ marginTop: `7px` }}>Prop&oacute;n una bebida ( o varias ;) )</label>
            <input className="form-control" type="text"
                name="telefono" id="telefono"
                onChange={handleInputChange} value={props.invitado.telefono}
            /> */}

        </div>
     );
}
 
export default FormularioModal;