import React, { useState, Fragment } from "react";

//import ReCAPTCHA from "react-google-recaptcha";

import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import HeaderFormulario from './HeaderFormulario'
import FormularioModal from "./FormularioModal";
import BotonOpciones from './BotonOpciones';
import UsuarioRegistrado from './UsuarioRegistrado';

import './ConfirmarAsistenciaBoton.css'

const ConfirmarAsistenciaBoton = () => {

    const initialFormState = {  
      idRsvp: "",
      campo1: "",
      campo2: "1",
      campo3: "",
      campo4: "0",
      campo5: "",
      campo6: "0",
      campo7: "0",
      campo8: "",
      campo9: "",
      campo10: ""
    }

    const [ invitado, setInvitado ]   = useState(initialFormState)
    const [ data, setData ]           = useState([])
    const [ errorPOST, setErrorPOST ] = useState(false)
    const [ modalInsertar, setModalInsertar ] = useState(false)

    const url = "https://acolores.es/ecardDemo/backendNew/rsvp.php";
  
  
    const modalCancel = () => {
      setInvitado(initialFormState);
      setModalInsertar(false);
    };
  
    const modalOpen = () => {
      setModalInsertar(true)
    }
  
    const peticionPost= async() =>{
      console.log(invitado);
      await axios.post(url, invitado)
      .then(response=>{
        setData(data.concat(response.data));
        console.log(response.data);
        setErrorPOST(false)
      }).catch(error=>{
        setErrorPOST(true)
        console.log("Error: " + error);
      })
      setModalInsertar(false)
    }
  
    if(data.length === 0){
      if(errorPOST){
        return(
          <Fragment>
              <button className="btn btn-outline-danger" onClick={modalOpen}>No se pudo registrar la asistencia</button>
              {/* <a  onClick={modalOpen} style={{"cursor":"pointer"}}>
                  <img src="./img/Confirmar_error.svg" alt=""/> 
                </a> */}
              <Modal isOpen={modalInsertar} id="modalError">
                <ModalHeader style={{ display: "block" }}>
                  <HeaderFormulario titulo={"Reg&iacute;strate a nuestra boda."} modalCancel={modalCancel} />
                </ModalHeader>
  
                <ModalBody>
                  <FormularioModal invitado={invitado} setInvitado={setInvitado}/>
                </ModalBody>          
  
                <ModalFooter>
                  <BotonOpciones peticionPost={peticionPost} modalCancel={modalCancel} />
                </ModalFooter>
              </Modal>
          </Fragment>
        );
      }else{
          return ( 
            <div className="AppModal">
              <Fragment>
                {/* <button className="btn btn-secondary" onClick={modalOpen}>Confirmar asistencia</button> */}
                <a  onClick={modalOpen} style={{"cursor":"pointer"}}>
                  <img id="botonRegistro" src="./img/Confirmar_boton.svg" alt=""/> 
                </a>
                
              </Fragment>
  
              <Modal isOpen={modalInsertar} className="Modal"> 
                <ModalHeader style={{ display: "block" }} className="ModalHeader">
                  <HeaderFormulario titulo={"Registrate a nuestra boda."} modalCancel={modalCancel} />
                </ModalHeader>
                <ModalBody className="ModalBody">
                  <FormularioModal invitado={invitado} setInvitado={setInvitado}/>
                </ModalBody>   
                <ModalFooter className="ModalFooter">
                  <BotonOpciones peticionPost={peticionPost} modalCancel={modalCancel} />
                </ModalFooter>
              </Modal>
            </div>
          );
      }
    }else{
        return(
          <UsuarioRegistrado></UsuarioRegistrado>
        );  
    }
}
 
export default ConfirmarAsistenciaBoton;