import React, {useRef, useEffect, useState, Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_6/data.json';

import Countdown from '../Countdown/Countdown';
import Timer from '../Countdown/Timer';

import "./Seccion_6.css"

const Seccion_6 = (props) => {

    const [altura, setAltura] = useState(0)
    const [ancho, setAncho]   = useState(0)
  
    useEffect(() => {
        setAltura( window.innerHeight )
        setAncho( window.innerWidth )
    }, [altura, ancho])
    
    const [animacion, setAnimacion] = useState(false);

    const refSeccion6 = useRef(null);

    const scrollCallback = (entries) => {
        if (entries[0].isIntersecting) {
            setAnimacion(true);
        }else{
            // do nothing
        }
    };

    useEffect(() => {
        // *** Grab the element related to this callback
        const { current } = refSeccion6;
        const observer = new IntersectionObserver(scrollCallback, {
            root: null,
            threshold: 1,
        });
        observer.observe(current);
        return () => {
            observer.disconnect(current); // *** Use the same element
        }
    }, [refSeccion6.current]); // *** Note dependency

    const styleBox = {
        top: 0,
        padding: 0,
        border: 0,
        "background-color": "transparent",
        width: "100px",
        height: "100px",
        position: "relative",
        margin: "auto",
        transform: "translate(0%, 400%)",
        zIndex: "-1000"
    };

    let styleImageHidden = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 500%)",
        visibility: "hidden",
        zIndex: "-200"
    };

    let styleImageShow = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 500%)",
        zIndex: "0"
    };     

    let styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 500%)",
        zIndex: "-100"
      };

    const style = {
        top: 0,
        margin: '0px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        position: "relative",
        transform: "translate(0%, -12%)",
        zIndex: "1000"
      };  

    if(ancho <= 600) {
        styleImageHidden = {
            top: 0,
            padding: 0,
            border: 0,
            height: "100vh",
            width: "100vw", 
            position: "absolute",
            margin: "auto",
            transform: "translate(-50%, 500%)",
            visibility: "hidden",
            zIndex: "-200"
        };
    
        styleImageShow = {
            top: 0,
            padding: 0,
            border: 0,
            height: "100vh",
            width: "100vw", 
            position: "absolute",
            margin: "auto",
            transform: "translate(-50%, 500%)",
            zIndex: "0"
        };     
    
        styleImage = {
            top: 0,
            padding: 0,
            border: 0,
            height: "100vh",
            width: "100vw", 
            position: "absolute",
            margin: "auto",
            transform: "translate(-50%, 500%)",
            zIndex: "-100"
          };
    }

    return (
        <Fragment>
            <div id="seccion6Box" style={styleBox} ref={refSeccion6}></div>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            /> 

            <img id="fonddoSeccion6" src="./imgFondos/Cierre.gif" style={animacion ? styleImageShow : styleImageHidden} alt="foto" />

            { animacion ? "" : <img id="fonddoSeccion6_1" src="./imgFondosSinAnimar/6.jpg" style={styleImage}></img> }

            <div className="contenedorTimer">
              <Timer deadline={"2024-01-01T00:00:00.000Z"} />
            </div>
            
        </Fragment>

    );
}
 
export default Seccion_6