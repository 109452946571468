import { useState, useContext } from 'react';

import MensajeContexto from './MensajeContexto';

function MensajeContextoProvider({children}){

    const [mostrarMensaje, setMostrarMensaje] = useState(false);

    const updateMostrarMensaje = (estado) => {
        setMostrarMensaje(estado);
    }

    return (
        <MensajeContexto.Provider value={{ mostrarMensaje, updateMostrarMensaje }}>
            {children}
        </MensajeContexto.Provider>
    )
}

export default MensajeContextoProvider