import React, {Fragment, useState, useRef} from 'react'

import ReCAPTCHA from "react-google-recaptcha";

import "./BotonOpciones.css"

const BotonOpciones = ( props ) => {

    const captcha = useRef(null);

    const [soyHumano, setSoyHumano] = useState(false);

    const onChange = () => {
        if( captcha.current.getValue ){
            setSoyHumano(true);
        }
    }    

    return ( 
        <Fragment>
            <div className="recapcha">
                <ReCAPTCHA
                    ref={captcha}
                    sitekey="6LczHvIjAAAAANjNPEb_aVvg9HITkVNUWnq1wLYN"
                    onChange={onChange}
                />    
            </div>
            <div className="botones">
                {/* <button className="cancelar"  onClick={() => props.modalCancel()}>Cancelar</button> */}
                
                &nbsp;&nbsp;&nbsp;
                {/* <button className="registar" onClick={() => props.peticionPost()} disabled={soyHumano ? false : true} >Aceptar</button> */}
                <button className={soyHumano ? "registar" : "noRegistrar"} onClick={() => props.peticionPost()} disabled={soyHumano ? false : true} >Aceptar</button>

                &nbsp;&nbsp;
            </div>
        </Fragment>
     );
}
 
export default BotonOpciones;