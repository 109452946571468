import React, {useState, useEffect, useContext, Fragment} from 'react';

import MensajeContexto from '../Mensaje/MensajeContexto';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_1/data.json';

import "./Seccion_1.css"

const Seccion_1 = (props) => {
    const [altura, setAltura] = useState(0)
    const [ancho, setAncho]   = useState(0)
  
    useEffect(() => {
        setAltura( window.innerHeight )
        const anchoAux = (window.innerHeight * 57) / 100
        setAncho( window.innerWidth )
    }, [altura, ancho])


    const { mostrarMensaje, updateMostrarMensaje } = useContext(MensajeContexto);

    let styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 0%)",
        zIndex: "-100"
      };

    let styleImageFondo = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 0%)",
        zIndex: "-200"
      };

    let style = {
        top: "0%",
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        transform: "translate(0%, 0%)",
      };  

    if(ancho <= 600) {
        styleImage = {
            top: 0,
            padding: 0,
            border: 0,
            height: "100vh",
            width: '100vw',
            position: "absolute",
            margin: "auto",
            transform: "translate(-50%, 0%)",
            zIndex: "-100"
          };

        styleImageFondo = {
            top: 0,
            padding: 0,
            border: 0,
            height: "100vh",
            width: "100vw", 
            position: "absolute",
            margin: "auto",
            transform: "translate(-50%, 0%)",
            zIndex: "-200"
          };          

        style = {
            top: "0%",
            margin: '-1px 0px 0px 0px',
            padding: '0px 0px 0px 0px',
            border: '0px 0px 0px 0px',
            height: "100vh",
            width: '100vw',
            transform: "translate(0%, 0%)",
          };        
    }

    const mensaje = () => {
        const timeout = setTimeout(() => {
            updateMostrarMensaje(true);
        }, 4000)      
    }    

    return (
        <Fragment>
            <Lottie 
                loop = "false"
                autoplay
                animationData={animationData}
                style={style}
                onComplete={mensaje}
            /> 

            <img id="fonddoSeccion1_1" src="./imgFondosSinAnimar/1.jpg" style={styleImageFondo}></img>

            <img id="fonddoSeccion1" src="./imgFondos/Iniciales.gif" style={styleImage}></img>

            {mostrarMensaje ? <div>
                                  <div class="scroll-up"></div>
                                  <div class="scroll-up2"></div>
                                  <div class="scroll-up3"></div>
                              </div>
                : "" }   
        </Fragment>
    );
}
 
export default Seccion_1