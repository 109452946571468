import { useEffect, useMemo, useState } from "react";

import "./Timer.css"

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const Timer = ({ deadline = new Date().toString() }) => {
    const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
    const [time, setTime] = useState(parsedDeadline - Date.now());

    useEffect(() => {
        const interval = setInterval(
            () => setTime(parsedDeadline - Date.now()),
            1000,
        );

        return () => clearInterval(interval);
    }, [parsedDeadline]);

    if( time > 0 ){
        return (
            <div className="timer">
                {Object.entries({
                    Días: time / DAY,
                    Horas: (time / HOUR) % 24,
                    Minutos: (time / MINUTE) % 60,
                    Segundos: (time / SECOND) % 60,
                }).map(([label, value]) => (
                    <div key={label} className="col">
                        <div className="box">
                            <p>{`${Math.floor(value)}`.padStart(2, "0")}</p>
                            <span className="text">{label}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    }else{
        return (
            <div className="timerFinalizado">
                <p>Tiempo de festejar !!!</p>
            </div>       
        );
    }
};

export default Timer