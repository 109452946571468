import React, {useRef, useEffect, useState, Fragment} from 'react';
import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_4/data.json';
import ConfirmarAsistenciaBoton from '../../components/ConfirmarAsistenciaBoton/ConfirmarAsistenciaBoton';


// Estilo
import "./Seccion_4.css"

const Seccion_4 = (props) => {

    const [altura, setAltura] = useState(0)
    const [ancho, setAncho]   = useState(0)

    useEffect(() => {
        setAltura( window.innerHeight )
        const anchoAux = (window.innerHeight * 57) / 100
        setAncho( window.innerWidth )
    }, [altura, ancho])

    const [animacion, setAnimacion] = useState(false);

    const refSeccion4 = useRef(null);

    const scrollCallback = (entries) => {
        if (entries[0].isIntersecting) {
            setAnimacion(true);
        }else{
            // do nothing
        }
    };

    useEffect(() => {
        // *** Grab the element related to this callback
        const { current } = refSeccion4;
        const observer = new IntersectionObserver(scrollCallback, {
            root: null,
            threshold: 1,
        });
        observer.observe(current);
        return () => {
            observer.disconnect(current); // *** Use the same element
        }
    }, [refSeccion4.current]); // *** Note dependency

    const styleBox = {
        top: 0,
        padding: 0,
        border: 0,
        "background-color": "transparent",
        width: "100px",
        height: "100px",
        position: "relative",
        margin: "auto",
        transform: "translate(0%, 400%)",
        zIndex: "-1000"
    };

    let styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 300%)",
        zIndex: "-100"
      };

    let styleImageHidden = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 300%)",
        visibility: "hidden",
        zIndex: "-200"
    };

    let styleImageShow = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 300%)",
        zIndex: "0"
    };    

    let style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    if(ancho <= 600) {  
        styleImage = {
            top: 0,
            padding: 0,
            border: 0,
            height: "100vh",
            width: "100vw", 
            position: "absolute",
            margin: "auto",
            transform: "translate(-50%, 300%)",
            zIndex: "-100"
          };
    
        styleImageHidden = {
            top: 0,
            padding: 0,
            border: 0,
            height: "100vh",
            width: "100vw", 
            position: "absolute",
            margin: "auto",
            transform: "translate(-50%, 300%)",
            visibility: "hidden",
            zIndex: "-200"
        };
    
        styleImageShow = {
            top: 0,
            padding: 0,
            border: 0,
            height: "100vh",
            width: "100vw", 
            position: "absolute",
            margin: "auto",
            transform: "translate(-50%, 300%)",
            zIndex: "0"
        };    
    }

    return (
        <Fragment>
            {/* <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            /> */}

            <img id="fonddoSeccion4" src="./imgFondos/Asistencia.gif" style={animacion ? styleImageShow : styleImageHidden} alt="foto" />

            { animacion ? "" : <img id="fonddoSeccion4_1" src="./imgFondosSinAnimar/4.jpg" style={styleImage}></img> }

            <div id="textoAsistencia">
                  <img src="./img/Asistencia.svg" alt=""/>
                  <div id="ConfirmarAsistenciaBoton" >
                    <ConfirmarAsistenciaBoton/>
                  </div>
            </div> 

            <div id="seccion4Box" style={styleBox} ref={refSeccion4}>{animacion}</div>
        </Fragment>

    );
}
 
export default Seccion_4